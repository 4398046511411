@use 'sass:map';

@use '@carbon/react/scss/colors';
@use '@carbon/react/scss/themes';
@use '@carbon/react/scss/theme';

$base-overrides: (
  // background
  background-brand: colors.$green-60,
  // button
  button-primary: colors.$green-60,
  button-primary-hover: colors.$green-60-hover,
  button-primary-active: colors.$green-80,
  // support
  support-info-inverse: colors.$green-60
);

$light-theme: map.merge(
  themes.$g10,
  map.merge(
    $base-overrides,
    (
      // border
      border-interactive: colors.$green-60,
      // link
      link-primary: colors.$green-60,
      link-primary-hover: colors.$green-70-hover,
      link-secondary: colors.$green-70,
      link-inverse: colors.$green-40,
      // icon
      icon-interactive: colors.$green-60,
      // button
      button-tertiary: colors.$green-60,
      button-tertiary-hover: colors.$green-60-hover,
      button-tertiary-active: colors.$green-80,
      // focus
      focus: colors.$green-60,
      // miscellaneous
      interactive: colors.$green-60,
      'highlight': colors.$green-20,
      // charts
      grid-bg: colors.$gray-10
    )
  )
);

$dark-theme: map.merge(
  themes.$g90,
  map.merge(
    $base-overrides,
    (
      // border
      border-interactive: colors.$green-50,
      // text
      text-on-color-disabled: colors.$gray-60,
      // link
      link-primary: colors.$green-40,
      link-primary-hover: colors.$green-30,
      link-secondary: colors.$green-30,
      link-inverse: colors.$green-60,
      // button
      button-tertiary: colors.$green-60,
      button-tertiary-hover: colors.$green-60-hover,
      button-tertiary-active: colors.$green-80,
      // focus
      focus-inverse: colors.$green-60,
      // miscellaneous
      interactive: colors.$green-50,
      'highlight': colors.$green-80,
      // charts
      grid-bg: colors.$gray-90
    )
  )
);

:root {
  @include theme.theme($dark-theme);
}

[data-carbon-theme='light'] {
  @include theme.theme($light-theme);
}
