@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/spacing';
@use '@carbon/react/scss/type';
@use '@carbon/react/scss/colors';
@use '@carbon/react/scss/components/tag';

// ** cds overrides **

// Allow comboboxes and dropdowns to overflow modal

.cds--modal-container {
  max-block-size: 90%;
  overflow: scroll;

  @include breakpoint-down(md) {
    max-block-size: 100%;
  }
}

.cds--modal-content {
  overflow: visible;
}

// don't wrap text in tags
.cds--tag {
  > span {
    white-space: nowrap;
  }
}

// hide separator in header
.cds--header__nav::before {
  height: 0;
  width: 0;
}

// allow full width for snippets
.cds--snippet {
  max-inline-size: none;
}

// grid labels
.cds--cc--threshold--label {
  color: colors.$gray-100 !important;
}

// ** yarl overrides **
.yarl__slide_wrapper {
  padding: 0 40px;
}

.yarl__counter {
  @include type.type-style('helper-text-02', true);
}

// ** ww global classes **

$sizes: sm, md, lg, xlg, max;
@each $size in $sizes {
  .ww--hide-#{$size}-down {
    @include breakpoint-down($size) {
      display: none;
    }
  }

  .ww--hide-#{$size}-up {
    @include breakpoint-up($size) {
      display: none;
    }
  }
}

// no-wrap helper
.ww--no-wrap {
  white-space: nowrap;
}
// wrap reset
.ww--wrap {
  white-space: normal;
}

// full width skeleton
.ww--skeleton_full-width {
  width: 100%;
}

// force not available icon to be inline with overflow menus in tables
.ww--div_not-available {
  padding-left: spacing.$spacing-04;
}

// show notifications at full width
.ww--notification_full-width {
  min-width: 100%;
}

//toggle tip
.ww--toggle-tip {
  display: flex;
  flex-direction: row;
  align-items: center;

  > span:first-of-type {
    margin-right: spacing.$spacing-02;
  }
}

// grid of tiles
.ww--tile-grid {
  padding: 0;

  > div {
    padding: spacing.$spacing-04 spacing.$spacing-04 spacing.$spacing-04 0;

    > div {
      // layer
      height: 100%;

      > div {
        // tile
        height: 100%;
      }
    }
  }

  .ww--metric-label {
    @include type.type-style('heading-02');

    + div {
      margin-bottom: spacing.$spacing-02;
    }
  }
}

// icon fill colors
.ww--fill-red {
  fill: colors.$red-60 !important;
}

.ww--fill-green {
  fill: colors.$green-60 !important;
}

.ww--fill-orange {
  fill: colors.$orange-40 !important;
}

// custom tag styles
.ww--tag-ok {
  background-color: tag.$tag-background-green;
  color: tag.$tag-color-green;
}

.ww--tag-warning {
  background-color: colors.$yellow-20;
  color: colors.$yellow-80;
}

.ww--tag-alert {
  background-color: tag.$tag-background-red;
  color: tag.$tag-color-red;
}

// spacer for modal with dropdown/combobox as last item
.ww--modal-spacer {
  margin-bottom: spacing.$spacing-10;
}

// confirm modals
.ww--confirm_modal-body_content {
  margin: spacing.$spacing-08;
  display: flex;
  justify-content: center;
  column-gap: spacing.$spacing-05;
  align-items: center;
}

.ww--confirm_modal-body_footer {
  @include type.type-style('helper-text-01', true);
}
